import { Link } from 'gatsby';
import React from 'react';
import Crosshairs from '../components/icons/Crosshairs';
import Map from '../components/icons/Map';
import { Layout } from '../components/Layout';
import logoAlsa from '../images/home/alsa-logo.gif';
import logoApega from '../images/home/apega-logo.gif';
import logoPsc from '../images/home/psc-logo.gif';
import imageSurveyWithTripod from '../images/home/surveyor-with-tripod-v2.jpg';

const Index = () => (
  <Layout
    title="Axiom Geomatics Land Surveyors | Real Property Reports Calgary, Red Deer"
    description="A Real Property Report (RPR) is a legal document including property dimensions, improvements, permanent features, and more relative to property lines."
    keywords="real property report, rpr, property survey, house survey, land survey, selling house survey, compliance, letter of compliance, stamp of compliance"
  >
    <main>
      <h1>Axiom Geomatics Land Surveyors Calgary & Area</h1>

      <div className="hp-buttons">
        <Link to="/order-a-real-property-report" className="btn btn-block">
          <Crosshairs width="1rem" height="1rem" />
          Request a Quote for an RPR
        </Link>
        <Link to="/order-a-development-permit-survey" className="btn btn-block">
          <Map width="1rem" height="1rem" />
          Request a Quote for a DP Survey
        </Link>
      </div>

      <img
        src={imageSurveyWithTripod}
        alt="Surveyor with tripod"
        className="img"
        width="810"
        height="450"
      />

      <p>
        Axiom Geomatics is a{' '}
        <strong>full service professional land surveying company in Calgary</strong> providing legal
        and construction survey services. We provide fast, efficient surveying solutions. We serve
        Calgary, Red Deer and surrounding communities.
      </p>

      <p>
        Our Municipal Surveys team specializes in{' '}
        <Link to="services/residential-surveys/real-property-reports">Real Property Report</Link>{' '}
        (RPR's),{' '}
        <Link to="services/construction-services/development-permits">
          Development Permit Surveys
        </Link>
        ,{' '}
        <Link to="services/residential-surveys/property-line-surveys">
          Property and Fence Line Surveys
        </Link>
        , <Link to="services/legal-surveys/subdivision-surveys">Subdivision</Link> and{' '}
        <Link to="services/legal-surveys/condominium-services">Condominium</Link> surveys. We
        provide advisory, design, compliance and legal land survey services to home owners, realtors
        and legal teams. We pride ourselves in understanding the specific needs of our clients and
        providing accurate, cost effective services in a very timely manner.
      </p>

      <p>
        Our Commercial & Industrial Services division works with engineers, developers, construction
        crews and utility service providers for{' '}
        <Link to="/services/legal-surveys/right-of-way-surveys">Right-of-Way surveys</Link>,{' '}
        <Link to="/services/construction-services/development-permits">Development Permits</Link>,{' '}
        <Link to="/services/construction-services/house-stakeouts">House Stakeouts</Link>,{' '}
        <Link to="/services/construction-services/lot-grading-certificates">
          Lot Grading services
        </Link>
        ,{' '}
        <Link to="/services/construction-services/large-building-construction">
          Large Building Construction
        </Link>
        ,{' '}
        <Link to="/services/construction-services/utility-construction-surveys">
          Utility Mapping
        </Link>{' '}
        and more.
      </p>

      <p>
        Not sure what you need? Have to order a rush{' '}
        <a href="https://axiomgeomatics.ca/services/residential-surveys/real-property-reports">
          Real Property Report
        </a>{' '}
        (RPR)? Get in touch with a <strong>certified Alberta Land Surveyor</strong> for a
        no-obligation chat!
      </p>
    </main>

    <aside>
      <p>
        <Link to="/order-a-real-property-report" className="btn btn-block">
          <Crosshairs width="1rem" height="1rem" />
          Request a Quote for an RPR
        </Link>
      </p>
      <p>
        <Link to="/order-a-development-permit-survey" className="btn btn-block">
          <Map width="1rem" height="1rem" />
          Request a Quote for a DP Survey
        </Link>
      </p>

      <h2>Recent Articles</h2>
      <ul>
        <li>
          <Link to="/articles/what-is-a-land-survey">What is a Land Survey</Link>
        </li>
        <li>
          <Link to="/articles/what-is-a-plot-plan">What is a Plot Plan</Link>
        </li>
        {/*
        <li>
          <Link to="/articles/deck-permit-requirements-in-calgary">
            Deck Permit Requirements in Calgary
          </Link>
        </li>
        */}
        <li>
          <Link to="/articles/certificate-of-title-alberta">Certificate of Title Alberta</Link>
        </li>
        <li>
          <Link to="/articles/bare-land-condo-vs-conventional-condo">
            Bare Land Condo vs Conventional Condo
          </Link>
        </li>
        <li>
          <Link to="/articles/land-surveyors-and-rprs">
            Land Surveyors and RPRs - What are they?
          </Link>
        </li>
        <li>
          <Link to="/articles/overland-drainage-right-of-ways">
            Overland Drainage Right of Ways
          </Link>
        </li>
      </ul>

      <h2>Popular Services</h2>
      <ul>
        <li>
          <Link to="/services/residential-surveys/real-property-reports">
            Real Property Reports
          </Link>
        </li>
        <li>
          <Link to="/services/construction-services/development-permits">
            Development Permit Surveys
          </Link>
        </li>
        <li>
          <Link to="/services/legal-surveys/subdivision-surveys">Subdivision Surveys</Link>
        </li>
        <li>
          <Link to="/services/legal-surveys/right-of-way-surveys">Right-of-Way Surveys</Link>
        </li>
        <li>
          <Link to="/services/legal-surveys/condominium-services">Condominium Services</Link>
        </li>
      </ul>

      <h2>Professional Affiliations</h2>
      <div className="profAffiliations">
        <img src={logoAlsa} alt="Alsa logo" width="65" height="64" />
        <a href="http://www.alsa.ab.ca/" target="alsa">
          Alberta Land Surveyors' Association
        </a>
      </div>
      <div className="profAffiliations">
        <img src={logoPsc} alt="PSC logo" width="65" height="41" />
        <a href="http://www.psc-gpc.ca/" target="psc">
          Professional Surveyors of Canada
        </a>
      </div>
      <div className="profAffiliations">
        <img src={logoApega} alt="APEGA logo" width="65" height="57" />
        <a href="http://www.apega.ca/" target="apega">
          Association of Professional Engineers and Geoscientists of Alberta
        </a>
      </div>
    </aside>
  </Layout>
);

export default Index;
